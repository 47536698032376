import api from './base'

// Login request
export const login = async (username, password) => {
  const response = await api.post('/labs/login-lab-user', { username, password });
  return response.data;
};

// Example request for fetching lab data
export const fetchLabData = async () => {
  const response = await api.get('/labs');
  return response.data;
};

export default api