import React, {useEffect} from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Login from './pages/login/Login';
import Main from './pages/main/Main';
import Report from './pages/report/Report';
import { useSelector, useDispatch } from 'react-redux';
import { restoreSession } from './data/slices/authSlice';

const App = () => {
  const dispatch = useDispatch();
  const { token } = useSelector((state) => state.auth);

  useEffect(() => {
    // Restore token and user information from sessionStorage on app load
    dispatch(restoreSession());
  }, [dispatch]);

  return (
    <Router>
      <Routes>
        <Route path="/" element={token ? <Main /> : <Login />} />
        <Route path="/treatment" element={token ? <Report /> : <Login />} />
      </Routes>
    </Router>
  );
};

export default App;
