import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { jwtDecode } from "jwt-decode";
import { login } from "./../../api/auth"; // Import the login function from the API service layer

// Async thunk for logging in
export const loginUser = createAsyncThunk(
  "auth/loginUser",
  async ({ username, password }) => {
    const response = await login(username, password);
    return response;
  }
);

const authSlice = createSlice({
  name: "auth",
  initialState: {
    user: {
      id: null,
      username: null,
      email: null,
      lab: {
        id: null,
        name: null,
        logo: null,
        street_address_line_1: null,
        street_address_line_2: null,
        city: null,
        state: null,
        country: null,
        contact: null,
        email: null,
      },
    },
    token: sessionStorage.getItem("token") || null,
    isAuthenticated: !!sessionStorage.getItem("token"), // Check if token exists
    loading: false,
    error: null,
  },
  reducers: {
    logout: (state) => {
      state.user = null;
      state.token = null;
      state.isAuthenticated = false;
      sessionStorage.removeItem("token");
    },
    restoreSession: (state) => {
      const token = sessionStorage.getItem("token");
      if (token) {
        state.token = token;
        const decodedToken = jwtDecode(token);
        state.user = { ...decodedToken }; // Update user with decoded token
        state.isAuthenticated = true;
      }
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(loginUser.pending, (state) => {
        state.loading = true;
      })
      .addCase(loginUser.fulfilled, (state, action) => {
        state.loading = false;
        state.token = action.payload.token;
        sessionStorage.setItem("token", action.payload.token);
        const decodedToken = jwtDecode(action.payload.token);
        state.user = { ...decodedToken }; // Update user with decoded token
        state.isAuthenticated = true;
        state.error = null;
      })
      .addCase(loginUser.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  },
});

export const { logout, restoreSession } = authSlice.actions;
export default authSlice.reducer;
