import { useSelector } from "react-redux";
import "./Report3.css"; // Optional for styling

const Report3 = () => {
	const { reportData, treatmentData } = useSelector((state) => state.treatment);

	// If treatmentData or reportData is missing, show an appropriate message.
	if (
		!treatmentData ||
		Object.keys(treatmentData.treatments || {}).length === 0
	) {
		return <p>No treatment data available.</p>;
	}

	// Helper function to find the resistance with the fewest treatment options
	const findLeastTreatmentResistance = (resistances) => {
		return Object.keys(resistances).reduce((leastRes, currentRes) => {
			const leastOptionsCount = Math.min(
				resistances[leastRes].primaryTreatments.length,
				resistances[leastRes].nonPrimaryTreatments.length
			);
			const currentOptionsCount = Math.min(
				resistances[currentRes].primaryTreatments.length,
				resistances[currentRes].nonPrimaryTreatments.length
			);
			return currentOptionsCount < leastOptionsCount ? currentRes : leastRes;
		});
	};

	return (
		<div>
			<h3>Treatment Report</h3>

			{/* Render details of careProvider, specimenID, etc. */}
			<p>Care Provider: {reportData.careProvider}</p>
			<p>Laboratory: {reportData.laboratory}</p>
			<p>Telephone: {reportData.telephone}</p>
			<p>Specimen ID: {reportData.specimenID}</p>
			<p>Drug Allergies: {reportData.drugAllergies}</p>

			{/* Primary Treatment Table */}
			<h4>Primary Treatments</h4>
			<table>
				<thead>
					<tr>
						<th>Pathogen</th>
						<th>Load Level</th>
						<th>Resistances</th>
						<th>Primary Treatment (Least Options)</th>
					</tr>
				</thead>
				<tbody>
					{Object.keys(treatmentData.treatments).map((pathogenName) => {
						const pathogenData = treatmentData.treatments[pathogenName];

						if (!pathogenData) return null;

						// Find the resistance with the least number of treatment options
						const leastTreatmentResistance = findLeastTreatmentResistance(
							pathogenData.resistances
						);
						const resistanceData =
							pathogenData.resistances[leastTreatmentResistance];

						return (
							<tr key={pathogenName}>
								<td>{pathogenName}</td>
								<td>{reportData.loadLevels?.[pathogenName] || "Unknown"}</td>
								<td>{Object.keys(pathogenData.resistances).join(", ")}</td>
								<td>
									{resistanceData.primaryTreatments.length > 0
										? resistanceData.primaryTreatments.join(", ")
										: "No Primary Treatments"}
								</td>
							</tr>
						);
					})}
				</tbody>
			</table>

			{/* Render common primary treatments */}
			<h4>Common Primary Treatments:</h4>
			<p>
				{treatmentData.commonPrimary?.allPathogens?.length > 0
					? treatmentData.commonPrimary.allPathogens.join(", ")
					: "No Common Primary Treatments"}
			</p>

			{/* Non-Primary Treatment Table */}
			<h4>Non-Primary Treatments</h4>
			<table>
				<thead>
					<tr>
						<th>Pathogen</th>
						<th>Load Level</th>
						<th>Resistances</th>
						<th>Non-Primary Treatment (Least Options)</th>
					</tr>
				</thead>
				<tbody>
					{Object.keys(treatmentData.treatments).map((pathogenName) => {
						const pathogenData = treatmentData.treatments[pathogenName];

						if (!pathogenData) return null;

						// Find the resistance with the least number of treatment options for non-primary treatments
						const leastTreatmentResistance = findLeastTreatmentResistance(
							pathogenData.resistances
						);
						const resistanceData =
							pathogenData.resistances[leastTreatmentResistance];

						return (
							<tr key={pathogenName}>
								<td>{pathogenName}</td>
								<td>{reportData.loadLevels?.[pathogenName] || "Unknown"}</td>
								<td>{Object.keys(pathogenData.resistances).join(", ")}</td>
								<td>
									{resistanceData.nonPrimaryTreatments.length > 0
										? resistanceData.nonPrimaryTreatments.join(", ")
										: "No Non-Primary Treatments"}
								</td>
							</tr>
						);
					})}
				</tbody>
			</table>

			{/* Render common non-primary treatments */}
			<h4>Common Non-Primary Treatments:</h4>
			<p>
				{treatmentData.commonNonPrimary?.allPathogens?.length > 0
					? treatmentData.commonNonPrimary.allPathogens.join(", ")
					: "No Common Non-Primary Treatments"}
			</p>

			{/* Overlapping Treatments */}
			<h4>Overlapping Treatments (if any):</h4>
			{Object.keys(treatmentData.treatments).map((pathogenName) => {
				const pathogenData = treatmentData.treatments[pathogenName];

				if (!pathogenData || !pathogenData.overlap) return null;

				return (
					<div key={`${pathogenName}-overlap`}>
						<h5>{pathogenName}</h5>
						{pathogenData.overlap.primary.length > 0 && (
							<p>Primary Overlaps: {pathogenData.overlap.primary.join(", ")}</p>
						)}
						{pathogenData.overlap.nonPrimary.length > 0 && (
							<p>
								Non-Primary Overlaps:{" "}
								{pathogenData.overlap.nonPrimary.join(", ")}
							</p>
						)}
					</div>
				);
			})}
		</div>
	);
};

export default Report3;
