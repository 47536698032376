import { configureStore } from '@reduxjs/toolkit';
import authReducer from './slices/authSlice';
import treatmentReducer from './slices/treatmentSlice';

const store = configureStore({
  reducer: {
    auth: authReducer,
    treatment: treatmentReducer,
  },
});

export default store;
