import React from 'react'
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from 'react-router-dom';
import { logout } from './../../data/slices/authSlice'

const Header = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const handleLogout = () => {
    dispatch(logout())
    navigate('/')
  }

  return (
    <header className="app-header">
      <nav className="navbar navbar-expand-lg navbar-light">
        <div className="navbar-collapse justify-content-end px-0" id="navbarNav">
          <ul className="navbar-nav flex-row ms-auto align-items-center justify-content-end">
            <button
              onClick={handleLogout}
              target="_blank"
              className="btn btn-outline-danger m-1"
            >
              Logout 
            </button>
          </ul>
        </div>
      </nav>
    </header>
  );
}

export default Header