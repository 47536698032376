import React from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import medicareLogo from './medicareLogo.png'
import medixbioLogo from './medixbioLogo.jpg'

const ReportHeader = () => {
  const navigate = useNavigate();
  const { user } = useSelector((state) => state.auth);
  return (
    <div className="row" style={{ display: 'flex', alignItems: 'center' }}>
        <div className="col-lg-3">
      <a
        onClick={() => navigate("/")}
        className="text-nowrap logo-img cursor-pointer"
      >
        <img src={user.lab.id === 2 ? medicareLogo : medixbioLogo} className="logo" id='logo' style={{ width: '100%', marginTop: '-25px', padding: '15px', maxWidth: '200px'}} alt="" />
      </a>
      </div>
      <div className="col-lg-9 card-body p-4">
        <h6>Version 1.1</h6>
        <h2>Antimicrobial Treatment Map</h2>
      </div>
    </div>
  );
};

export default ReportHeader;
