import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Header from "../../components/header/Header";
import { useNavigate } from "react-router-dom";
import {
	Autocomplete,
	TextField,
	Button,
	MenuItem,
	CircularProgress,
	FormControl,
} from "@mui/material";

import {
	getTreatment,
	getTreatment2,
	getPathogens,
	getAmr,
	setReportData,
} from "../../data/slices/treatmentSlice";
import ReportHeader from "../../components/reportHeader/ReportHeader";

const Main = () => {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const { user, token } = useSelector((state) => state.auth);
	const { pathogens, amr, treatmentData, status, error } = useSelector(
		(state) => state.treatment
	);

	const [careProvider, setCareProvider] = useState("");
	const [laboratory, setLaboratory] = useState(user?.lab?.name);
	const [labContact, setLabContact] = useState(
		user?.username?.charAt(0).toUpperCase() + user?.username?.slice(1)
	);
	const [telephone, setTelephone] = useState(user.lab.contact);

	const [specimenID, setSpecimenID] = useState("");
	const [specimenType, setSpecimenType] = useState("");
	const [drugAllergies, setDrugAllergies] = useState("Not applicable");
	const [reportDate, setReportDate] = useState("");

	const [selectedPathogens, setSelectedPathogens] = useState([]);
	const [selectedAMRs, setSelectedAMRs] = useState([]);
	const [filteredAMR, setFilteredAMR] = useState([...amr]);

	useEffect(() => {
		// Get the current date and format it
		const date = new Date(Date.now());
		const formattedDate = date.toLocaleDateString("en-US", {
			month: "short", // Abbreviated month name
			day: "numeric", // Numeric day (1-31)
			year: "numeric", // Full year (2024)
		});
		setLaboratory(user?.lab?.name);
		setLabContact(
			user?.username?.charAt(0).toUpperCase() + user?.username?.slice(1)
		);
		setTelephone(user.lab.contact);
		setReportDate(formattedDate);
	}, [user]);

	useEffect(() => {
		dispatch(getPathogens());
		dispatch(getAmr());
	}, [dispatch]);

	useEffect(() => {
		if (selectedAMRs.includes("None")) {
			setFilteredAMR([{ id: 16, name: "None" }]);
		} else if (selectedAMRs.length > 0) {
			const updatedAMR = amr.filter((entry) => entry.id !== 16);
			setFilteredAMR([...updatedAMR]);
		} else {
			setFilteredAMR([...amr]);
		}
	}, [selectedAMRs, amr]);

	// State to store the high/low selection for each selected item
	const [selectionLevels, setSelectionLevels] = useState({});

	const handleLevelChange = (pathogen, event) => {
		const newLoad = event.target.value;
		setSelectionLevels((prevLevels) => ({
			...prevLevels,
			[pathogen]: newLoad,
		}));
	};

	const handlePathogenSelection = (event, newValue) => {
		setSelectedPathogens(newValue);

		// Initialize levels for newly selected pathogens
		const newLevels = { ...selectionLevels };
		newValue.forEach((pathogen) => {
			if (!newLevels[pathogen]) {
				newLevels[pathogen] = "Low"; // Default to 'Low'
			}
		});

		// Remove levels for deselected pathogens
		Object.keys(newLevels).forEach((pathogen) => {
			if (!newValue.includes(pathogen)) {
				delete newLevels[pathogen];
			}
		});

		setSelectionLevels(newLevels);
	};

	// const handleSubmit = () => {
	// 	const reportData = {
	// 		pathogens: selectedPathogens,
	// 		amrs: selectedAMRs,
	// 		careProvider,
	// 		laboratory,
	// 		labContact,
	// 		telephone,
	// 		specimenID,
	// 		specimenType,
	// 		drugAllergies,
	// 		reportDate,
	// 	};
	// 	dispatch(setReportData(reportData));
	// 	dispatch(
	// 		getTreatment({
	// 			token,
	// 			pathogens: selectedPathogens.join(","),
	// 			amr: selectedAMRs.join(","),
	// 		})
	// 	).then(() => navigate("/treatment"));
	// };

	const handleSubmit2 = () => {
		const reportData = {
			pathogens: selectedPathogens,
			amrs: selectedAMRs,
			loadLevels: selectionLevels,
			careProvider,
			laboratory,
			labContact,
			telephone,
			specimenID,
			specimenType,
			drugAllergies,
			reportDate,
		};

		dispatch(setReportData(reportData));
		dispatch(
			getTreatment2({
				token,
				pathogens: selectedPathogens,
				amr: selectedAMRs,
				loadLevels: selectionLevels,
			})
		).then(() => navigate("/treatment3"));
	};

	if (status === "loading") {
		return <CircularProgress />;
	}

	if (status === "failed") {
		return <div>Error: {error}</div>;
	}

	return (
		<>
			{/* <Sidebar /> */}
			<div className="body-wrapper">
				<Header />
				<div className="container-fluid">
					<ReportHeader />
					<div className="row">
						<div className="col-lg-6 d-flex align-items-strech">
							<div className="container-fluid">
								<div className="card">
									<div className="card-body">
										<h5 className="card-title fw-semibold mb-4">
											Test Information
										</h5>

										<div className="mb-3">
											<label
												htmlFor="exampleInputEmail1"
												className="form-label"
											>
												Care Provider
											</label>
											<input
												type="email"
												className="form-control"
												id="exampleInputEmail1"
												aria-describedby="emailHelp"
												value={careProvider}
												onChange={(e) => setCareProvider(e.target.value)}
											></input>
											<br />
											<label
												htmlFor="exampleInputEmail1"
												className="form-label"
											>
												Laboratory
											</label>
											<input
												type="email"
												className="form-control"
												id="exampleInputEmail1"
												aria-describedby="emailHelp"
												value={laboratory}
												disabled
											></input>
											<br />
											<label
												htmlFor="exampleInputEmail1"
												className="form-label"
											>
												Lab Contact
											</label>
											<input
												type="email"
												className="form-control"
												id="exampleInputEmail1"
												aria-describedby="emailHelp"
												value={labContact}
												disabled
											></input>
											<br />
											<label
												htmlFor="exampleInputEmail1"
												className="form-label"
											>
												Telephone
											</label>
											<input
												type="email"
												value={telephone}
												disabled
												className="form-control"
												id="exampleInputEmail1"
												aria-describedby="emailHelp"
											></input>
											<br />
										</div>
									</div>
								</div>
							</div>
						</div>

						<div className="col-lg-6 d-flex align-items-strech">
							<div className="container-fluid">
								<div className="card">
									<div className="card-body">
										<h5 className="card-title fw-semibold mb-4">
											Patient Information
										</h5>

										<div className="mb-3">
											<label
												htmlFor="exampleInputEmail1"
												className="form-label"
											>
												Specimen ID
											</label>
											<input
												type="email"
												className="form-control"
												id="exampleInputEmail1"
												aria-describedby="emailHelp"
												value={specimenID}
												onChange={(e) => setSpecimenID(e.target.value)}
											></input>
											<br />
											<label
												htmlFor="exampleInputEmail1"
												className="form-label"
											>
												Specimen type
											</label>
											<select
												value={specimenType}
												className="form-select"
												onChange={(e) => setSpecimenType(e.target.value)}
											>
												<option value="" disabled>
													Please select
												</option>
												<option value="Urine">Urine</option>
												<option value="Swab">Swab</option>
												<option value="Blood">Blood</option>
												<option value="Other">Other</option>
											</select>
											<br />
											<label
												htmlFor="exampleInputEmail1"
												className="form-label"
											>
												Drug allergies
											</label>
											<input
												type="email"
												className="form-control"
												id="exampleInputEmail1"
												aria-describedby="emailHelp"
												value={drugAllergies}
												disabled
											></input>
											<br />
											<label
												htmlFor="exampleInputEmail1"
												className="form-label"
											>
												Report date
											</label>
											<input
												type="email"
												className="form-control"
												id="exampleInputEmail1"
												aria-describedby="emailHelp"
												value={reportDate}
												disabled
											></input>
											<br />
										</div>
									</div>
								</div>
							</div>
						</div>

						<div className="col-lg-12 d-flex align-items-strech">
							<div className="container-fluid">
								<div className="card">
									<div className="card-body">
										<h5 className="card-title fw-semibold mb-4">
											Treatment Map
										</h5>

										<div className="mb-3">
											<div className="mb-3">
												<label
													htmlFor="exampleInputEmail1"
													className="form-label"
												>
													Pathogens detected
												</label>
												<Autocomplete
													multiple
													options={pathogens.map((pathogen) => pathogen.name)}
													value={selectedPathogens}
													onChange={handlePathogenSelection}
													renderInput={(params) => (
														<TextField {...params} label="Select Pathogens" />
													)}
												/>
												<div className="row">
													{selectedPathogens.map((pathogen) => (
														<div className="col-md-3">
															<FormControl
																key={pathogen}
																fullWidth
																margin="normal"
															>
																<TextField
																	select
																	label={`${pathogen} Level`}
																	value={selectionLevels[pathogen] || "Low"}
																	onChange={(e) =>
																		handleLevelChange(pathogen, e)
																	}
																>
																	<MenuItem value="Low">Low</MenuItem>
																	<MenuItem value="High">High</MenuItem>
																</TextField>
															</FormControl>
														</div>
													))}
												</div>
											</div>
											<label
												htmlFor="exampleInputEmail1"
												className="form-label"
											>
												AMRs detected
											</label>
											<Autocomplete
												multiple
												style={{ padding: "0", height: "50px" }}
												options={filteredAMR.map((p) => p.name)}
												onChange={(event, newValue) =>
													setSelectedAMRs(newValue)
												}
												renderInput={(params) => (
													<>
														<TextField {...params} />
													</>
												)}
											/>
											<div
												style={{ display: "flex" }}
												className="flex-row align-items-center justify-content-end"
											>
												<button
													type="submit"
													className="btn btn-primary"
													style={{ width: "30%", marginTop: "30px" }}
													disabled={
														selectedAMRs.length === 0 &&
														selectedPathogens.length === 0
													}
													onClick={handleSubmit2}
												>
													Generate Report
												</button>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default Main;
