import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import Sidebar from "../../components/sidebar/Sidebar";
import Header from "../../components/header/Header";
import ReportHeader from "../../components/reportHeader/ReportHeader";
import { useNavigate } from "react-router-dom";
import html2pdf from "html2pdf.js";

import Accordion from '@mui/material/Accordion';
import AccordionActions from '@mui/material/AccordionActions';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const Report = () => {
  const navigate = useNavigate();
  const { user } = useSelector((state) => state.auth);
  const { reportData } = useSelector((state) => state.treatment);
  const { treatmentData, pathogens } = useSelector((state) => state.treatment);
  const [recommendedTreatment, setRecommendedTreatment] = useState(0);
  const [print, setPrint] = useState(false)

  const formatCombination = (combination) => {
    const words = combination.split(" ");
    if (words.length > 1) {
      const lastWord = words.pop(); // Remove and capture the last word
      return `${words.join(" ")} (${lastWord})`;
    }
    return combination; // If it's a single word, return as-is
  };

  const filteredPathogens = pathogens.filter((pathogen) =>
    reportData?.pathogens.includes(pathogen.name)
  );

  const preloadImage = (url) => {
    return new Promise((resolve, reject) => {
      const img = new Image();
      img.src = url;
      img.onload = resolve;
      img.onerror = reject;
    });
  };

  const handleReport = async () => {
    setPrint(true)
    const element = document.getElementById("printable");
    html2pdf()
      .from(element)
      .set({
        margin: 1,
        filename: `Report-${reportData?.specimenID}-${reportData?.reportDate}.pdf`,
        image: { type: "jpeg", quality: 1 },
        html2canvas: {
          scale: 4,
          logging: true,
          dpi: 300,
          letterRendering: true,
          useCORS: true, // Allow cross-origin images
          allowTaint: false,
        },
        jsPDF: { unit: "in", format: "letter", orientation: "portrait" },
      })
      .save()
      .finally(() => {
        setPrint(false);
      });;
  };

  useEffect(() => {
    if (treatmentData.length > 2) {
      setRecommendedTreatment(2);
    } else {
      setRecommendedTreatment(1);
    }
  }, []);

  useEffect(() => {
    if (reportData.pathogens.length === 0) {
      navigate("/");
    }
  }, []);

  return (
    <>
      {/* <Sidebar /> */}
      <div className="body-wrapper">
        <Header />
        <div className="container-fluid print-content" id="printable">
          <ReportHeader />
          <div style={{ display: print ? "none" : "block" }}>
            <div className="row">
              <div className="col-md-3 d-flex align-items-strech"></div>
              <div
                className="col-md-4 col-sm-6 d-flex align-items-strech"
                style={{ borderTop: "1px solid #e2e2e2", paddingTop: "25px" }}
              >
                <div className="container-fluid">
                  <div className="row">
                    <div className="col-md-6 col-sm-6 d-flex align-items-strech">
                      <p
                        style={{ marginBottom: "4px", fontSize: "15px" }}
                        className="form-label"
                      >
                        Care provider:
                      </p>
                    </div>
                    <div className="col-md-6 col-sm-6 d-flex align-items-strech">
                      <p
                        style={{
                          marginBottom: "4px",
                          fontWeight: "500",
                          fontSize: "15px",
                        }}
                        className="form-label"
                      >
                        {reportData?.careProvider}
                      </p>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-6 col-sm-6 d-flex align-items-strech">
                      <p
                        style={{ marginBottom: "4px", fontSize: "15px" }}
                        className="form-label"
                      >
                        Lab:
                      </p>
                    </div>
                    <div className="col-md-6 col-sm-6 d-flex align-items-strech">
                      <p
                        style={{
                          marginBottom: "4px",
                          fontWeight: "500",
                          fontSize: "15px",
                        }}
                        className="form-label"
                      >
                        {reportData?.laboratory}
                      </p>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-6 col-sm-6 d-flex align-items-strech">
                      <p
                        style={{ marginBottom: "4px", fontSize: "15px" }}
                        className="form-label"
                      >
                        Lab Contact:
                      </p>
                    </div>
                    <div className="col-md-6 col-sm-6 d-flex align-items-strech">
                      <p
                        style={{
                          marginBottom: "4px",
                          fontWeight: "500",
                          fontSize: "15px",
                        }}
                        className="form-label"
                      >
                        {reportData?.labContact}
                      </p>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-6 col-sm-6 d-flex align-items-strech">
                      <p
                        style={{ marginBottom: "4px", fontSize: "15px" }}
                        className="form-label"
                      >
                        Telephone:
                      </p>
                    </div>
                    <div className="col-md-6 col-sm-6 d-flex align-items-strech">
                      <p
                        style={{
                          marginBottom: "4px",
                          fontWeight: "500",
                          fontSize: "15px",
                        }}
                        className="form-label"
                      >
                        {reportData?.telephone}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div
                className="col-md-4  col-sm-6 d-flex align-items-strech"
                style={{ borderTop: "1px solid #e2e2e2", paddingTop: "25px" }}
              >
                <div className="container-fluid">
                  <div className="row">
                    <div className="col-md-6 d-flex align-items-strech">
                      <p
                        style={{ marginBottom: "4px", fontSize: "15px" }}
                        className="form-label"
                      >
                        Specimen ID:
                      </p>
                    </div>
                    <div className="col-md-6 d-flex align-items-strech">
                      <p
                        style={{
                          marginBottom: "4px",
                          fontWeight: "500",
                          fontSize: "15px",
                        }}
                        className="form-label"
                      >
                        {reportData?.specimenID}
                      </p>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-6 d-flex align-items-strech">
                      <p
                        style={{ marginBottom: "4px", fontSize: "15px" }}
                        className="form-label"
                      >
                        Specimen Type:
                      </p>
                    </div>
                    <div className="col-md-6 d-flex align-items-strech">
                      <p
                        style={{
                          marginBottom: "4px",
                          fontWeight: "500",
                          fontSize: "15px",
                        }}
                        className="form-label"
                      >
                        {reportData?.specimenType}
                      </p>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-6 d-flex align-items-strech">
                      <p
                        style={{ marginBottom: "4px", fontSize: "15px" }}
                        className="form-label"
                      >
                        Drug allergies:
                      </p>
                    </div>
                    <div className="col-md-6 d-flex align-items-strech">
                      <p
                        style={{
                          marginBottom: "4px",
                          fontWeight: "500",
                          fontSize: "15px",
                        }}
                        className="form-label"
                      >
                        {reportData?.drugAllergies}
                      </p>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-6 d-flex align-items-strech">
                      <p
                        style={{ marginBottom: "4px", fontSize: "15px" }}
                        className="form-label"
                      >
                        Report Date:
                      </p>
                    </div>
                    <div className="col-md-6 d-flex align-items-strech">
                      <p
                        style={{
                          marginBottom: "4px",
                          fontWeight: "500",
                          fontSize: "15px",
                        }}
                        className="form-label"
                      >
                        {reportData?.reportDate}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* print */}
          <div style={{ display: print ? "block" : "none" }}>
            <div className="row">
              <div
                className="col-md-6 col-sm-6 d-flex align-items-strech"
                style={{ borderTop: "1px solid #e2e2e2", paddingTop: "25px" }}
              >
                <div className="container-fluid">
                  <div className="row">
                    <div className="col-md-6 col-sm-6 d-flex align-items-strech">
                      <p
                        style={{ marginBottom: "4px", fontSize: "15px" }}
                        className="form-label"
                      >
                        Care provider:
                      </p>
                    </div>
                    <div className="col-md-6 col-sm-6 d-flex align-items-strech">
                      <p
                        style={{
                          marginBottom: "4px",
                          fontWeight: "500",
                          fontSize: "15px",
                        }}
                        className="form-label"
                      >
                        {reportData?.careProvider}
                      </p>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-6 col-sm-6 d-flex align-items-strech">
                      <p
                        style={{ marginBottom: "4px", fontSize: "15px" }}
                        className="form-label"
                      >
                        Lab:
                      </p>
                    </div>
                    <div className="col-md-6 col-sm-6 d-flex align-items-strech">
                      <p
                        style={{
                          marginBottom: "4px",
                          fontWeight: "500",
                          fontSize: "15px",
                        }}
                        className="form-label"
                      >
                        {reportData?.laboratory}
                      </p>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-6 col-sm-6 d-flex align-items-strech">
                      <p
                        style={{ marginBottom: "4px", fontSize: "15px" }}
                        className="form-label"
                      >
                        Lab Contact:
                      </p>
                    </div>
                    <div className="col-md-6 col-sm-6 d-flex align-items-strech">
                      <p
                        style={{
                          marginBottom: "4px",
                          fontWeight: "500",
                          fontSize: "15px",
                        }}
                        className="form-label"
                      >
                        {reportData?.labContact}
                      </p>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-6 col-sm-6 d-flex align-items-strech">
                      <p
                        style={{ marginBottom: "4px", fontSize: "15px" }}
                        className="form-label"
                      >
                        Telephone:
                      </p>
                    </div>
                    <div className="col-md-6 col-sm-6 d-flex align-items-strech">
                      <p
                        style={{
                          marginBottom: "4px",
                          fontWeight: "500",
                          fontSize: "15px",
                        }}
                        className="form-label"
                      >
                        {reportData?.telephone}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div
                className="col-md-6  col-sm-6 d-flex align-items-strech"
                style={{ borderTop: "1px solid #e2e2e2", paddingTop: "25px" }}
              >
                <div className="container-fluid">
                  <div className="row">
                    <div className="col-md-6 d-flex align-items-strech">
                      <p
                        style={{ marginBottom: "4px", fontSize: "15px" }}
                        className="form-label"
                      >
                        Specimen ID:
                      </p>
                    </div>
                    <div className="col-md-6 d-flex align-items-strech">
                      <p
                        style={{
                          marginBottom: "4px",
                          fontWeight: "500",
                          fontSize: "15px",
                        }}
                        className="form-label"
                      >
                        {reportData?.specimenID}
                      </p>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-6 d-flex align-items-strech">
                      <p
                        style={{ marginBottom: "4px", fontSize: "15px" }}
                        className="form-label"
                      >
                        Specimen Type:
                      </p>
                    </div>
                    <div className="col-md-6 d-flex align-items-strech">
                      <p
                        style={{
                          marginBottom: "4px",
                          fontWeight: "500",
                          fontSize: "15px",
                        }}
                        className="form-label"
                      >
                        {reportData?.specimenType}
                      </p>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-6 d-flex align-items-strech">
                      <p
                        style={{ marginBottom: "4px", fontSize: "15px" }}
                        className="form-label"
                      >
                        Drug allergies:
                      </p>
                    </div>
                    <div className="col-md-6 d-flex align-items-strech">
                      <p
                        style={{
                          marginBottom: "4px",
                          fontWeight: "500",
                          fontSize: "15px",
                        }}
                        className="form-label"
                      >
                        {reportData?.drugAllergies}
                      </p>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-6 d-flex align-items-strech">
                      <p
                        style={{ marginBottom: "4px", fontSize: "15px" }}
                        className="form-label"
                      >
                        Report Date:
                      </p>
                    </div>
                    <div className="col-md-6 d-flex align-items-strech">
                      <p
                        style={{
                          marginBottom: "4px",
                          fontWeight: "500",
                          fontSize: "15px",
                        }}
                        className="form-label"
                      >
                        {reportData?.reportDate}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            className="row"
            style={{
              marginTop: "40px",
              paddingTop: "10px",
              borderTop: "1px solid #e2e2e2",
            }}
          >
            <h4 style={{ fontWeight: "600", paddingBottom: "20px" }}>
              TEST RESULTS
            </h4>
            <div className="col-md-1"></div>
            <div className="col-md-8">
              <h5 style={{ fontWeight: "600" }}>
                PATHOGENIC ORGANISMS DETECTED
              </h5>
            </div>
            {/* <div className="col-md-3">
              <h5 style={{ fontWeight: "600" }}>AMR GENES</h5>
            </div> */}
            <div className="col-md-3">
              <h5 style={{ fontWeight: "600" }}>LOAD (CFU/mL)</h5>
            </div>
          </div>

          <div className="row">
            <div className="col-md-1"></div>
            <div
              className="col-md-8"
              style={{ borderTop: "1px solid #e2e2e2", paddingTop: "10px" }}
            >
              <h5 style={{ fontWeight: "500" }}>
                {reportData.pathogens.join(", ")}
                <br/>
                <em>
                {reportData.amrs.join(", ")}</em>
              </h5>
            </div>
            {/* <div
              className="col-md-3"
              style={{ borderTop: "1px solid #e2e2e2", paddingTop: "10px" }}
            >
              <h5 style={{ fontWeight: "500" }}>
                {reportData.amrs.join(", ")}
              </h5>
            </div> */}
            <div
              className="col-md-3"
              style={{ borderTop: "1px solid #e2e2e2", paddingTop: "10px" }}
            >
              <span className="badge bg-danger rounded-3 fw-semibold">
                HIGH
              </span>
            </div>

            <div className="col-md-1"></div>
            <div className="col-md-11" style={{ paddingLeft: "50px" }}>
              <p
                style={{
                  fontWeight: "400",
                  fontSize: "15px",
                  paddingTop: "10px",
                  display: print ? 'none' : 'block'
                }}
              >
                {filteredPathogens.length > 0 ? (
                  filteredPathogens.map((pathogen) => (
                      <Accordion key={pathogen.id}>
                        <AccordionSummary
                          expandIcon={<ExpandMoreIcon />}
                          aria-controls="panel1-content"
                          id="panel1-header"
                        >
                         {pathogen.name}
                        </AccordionSummary>
                        <AccordionDetails>
                        {pathogen.description}
                        </AccordionDetails>
                      </Accordion>
                  ))
                ) : (
                  <p>No pathogens found in the report.</p>
                )}
              </p>
              <p
                style={{
                  fontWeight: "400",
                  fontSize: "15px",
                  paddingTop: "10px",
                  display: print ? 'block' : 'none'
                }}
              >
                {filteredPathogens.length > 0 ? (
                  filteredPathogens.map((pathogen) => (
                    <div key={pathogen.id}>
                    {/* <h3>{pathogen.name}</h3> */}
                    <p>{pathogen.description}</p>
                  </div>
                  ))
                ) : (
                  <p>No pathogens found in the report.</p>
                )}
              </p>
            </div>
          </div>

          <div
            className="row"
            style={{
              marginTop: "40px",
              paddingTop: "10px",
              borderTop: "1px solid #e2e2e2",
            }}
          >
            <h4 style={{ fontWeight: "600", paddingBottom: "20px" }}>
              RECOMMENDED FIRST LINE OF TREATMENT
            </h4>
            <div className="col-md-1"></div>
            <div className="col-md-5">
              <h5 style={{ fontWeight: "600" }}>ANTIBIOTIC TREATMENT</h5>
            </div>
            <div className="col-md-3">
              <h5 style={{ fontWeight: "600", visibility: "hidden" }}>
                PATHOGENS TARGETED
              </h5>
            </div>
            <div className="col-md-3">
              <h5 style={{ fontWeight: "600" }}>TREATMENT SENSITIVITY</h5>
            </div>
          </div>

          {treatmentData?.map((treatment, index) => {
            if (index < recommendedTreatment)
              return (
                <div className="row">
                  <div className="col-md-1"></div>
                  <div
                    className="col-md-5"
                    style={{
                      borderTop: "1px solid #e2e2e2",
                      paddingTop: "10px",
                    }}
                  >
                    <h5 style={{ fontWeight: "500" }}>{treatment.name}</h5>
                  </div>
                  <div
                    className="col-md-3"
                    style={{
                      borderTop: "1px solid #e2e2e2",
                      paddingTop: "10px",
                    }}
                  >
                    <h5 style={{ fontWeight: "500", visibility: "hidden" }}>
                      {treatment?.recommended_combinations.length !== 0 ? (
                        treatment.recommended_combinations?.map(
                          (combination, i) => (
                            <span key={i}>
                              {formatCombination(combination)}
                            </span>
                          )
                        )
                      ) : (
                        <span>{reportData.pathogens.join(", ")}</span>
                      )}
                    </h5>
                  </div>
                  <div
                    className="col-md-3"
                    style={{
                      borderTop: "1px solid #e2e2e2",
                      paddingTop: "10px",
                    }}
                  >
                    <div class="me-4">
                      {treatment.recommended ? (
                        <>
                          <span class="round-8 bg-success rounded-circle me-2 d-inline-block"></span>
                          <span>Good</span>
                        </>
                      ) : (
                        <>
                          <span class="round-8 bg-warning rounded-circle me-2 d-inline-block"></span>
                          <span>Moderate</span>
                        </>
                      )}
                    </div>
                  </div>
                  <div className="col-md-1"></div>
                  <div className="col-md-11" style={{ paddingLeft: "50px" }}>
                    <p
                      style={{
                        fontWeight: "400",
                        fontSize: "15px",
                        paddingTop: "10px",
                      }}
                    >
                      {treatment.description}
                    </p>
                  </div>
                </div>
              );
          })}
          {treatmentData.length > recommendedTreatment ? (
            <>
              <div
                className="row"
                style={{
                  marginTop: "40px",
                  paddingTop: "10px",
                  borderTop: "1px solid #e2e2e2",
                }}
              >
                <h4 style={{ fontWeight: "600", paddingBottom: "20px" }}>
                  RECOMMENDED ALTERNATE LINE OF TREATMENT
                </h4>
                <div className="col-md-1"></div>
                <div className="col-md-5">
                  <h5 style={{ fontWeight: "600" }}>ANTIBIOTIC TREATMENT</h5>
                </div>
                <div className="col-md-3">
                  <h5 style={{ fontWeight: "600", visibility: "hidden" }}>
                    PATHOGENS TARGETED
                  </h5>
                </div>
                <div className="col-md-3">
                  <h5 style={{ fontWeight: "600" }}>TREATMENT SENSITIVITY</h5>
                </div>
              </div>
              {treatmentData?.map((treatment, index) => {
                if (index >= recommendedTreatment)
                  return (
                    <div className="row">
                      <div className="col-md-1"></div>
                      <div
                        className="col-md-5"
                        style={{
                          borderTop: "1px solid #e2e2e2",
                          paddingTop: "10px",
                        }}
                      >
                        <h5 style={{ fontWeight: "500" }}>{treatment.name}</h5>
                      </div>
                      <div
                        className="col-md-3"
                        style={{
                          borderTop: "1px solid #e2e2e2",
                          paddingTop: "10px",
                        }}
                      >
                        <h5 style={{ fontWeight: "500", visibility: "hidden" }}>
                          {treatment?.recommended_combinations.length !== 0 ? (
                            treatment.recommended_combinations?.map(
                              (combination, i) => (
                                <span key={i}>
                                  {formatCombination(combination)}
                                </span>
                              )
                            )
                          ) : (
                            <span>{reportData.pathogens.join(", ")}</span>
                          )}
                        </h5>
                      </div>
                      <div
                        className="col-md-3"
                        style={{
                          borderTop: "1px solid #e2e2e2",
                          paddingTop: "10px",
                        }}
                      >
                        <div class="me-4">
                          {treatment.recommended ? (
                            <>
                              <span class="round-8 bg-success rounded-circle me-2 d-inline-block"></span>
                              <span>Good</span>
                            </>
                          ) : (
                            <>
                              <span class="round-8 bg-warning rounded-circle me-2 d-inline-block"></span>
                              <span>Moderate</span>
                            </>
                          )}
                        </div>
                      </div>
                      <div className="col-md-1"></div>
                      <div
                        className="col-md-11"
                        style={{ paddingLeft: "50px" }}
                      >
                        <p
                          style={{
                            fontWeight: "400",
                            fontSize: "15px",
                            paddingTop: "10px",
                          }}
                        >
                          {treatment.description}
                        </p>
                      </div>
                    </div>
                  );
              })}
            </>
          ) : null}
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              marginTop: "100px",
            }}
          >
            <button
              className="btn btn-primary"
              style={{ width: "300px", display: print ? "none" : "block" }}
              onClick={handleReport}
            >
              Print
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default Report;
