// features/treatment/treatmentSlice.js
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {
	fetchTreatment,
	fetchPathogens,
	fetchAMR,
	fetchTreatment2,
} from "../../api/treatment";

const initialState = {
	treatmentData: {
		treatments: {}, // Pathogen -> Resistance -> { primaryTreatments, nonPrimaryTreatments, overlaps }
		commonTreatments: {
			commonPrimary: [],
			commonNonPrimary: [],
		},
	},
	reportData: {
		pathogens: [], // selected pathogens
		amrs: [], // selected AMRs (resistances)
		loadLevels: {}, // pathogen -> load level (High/Low)
		careProvider: null,
		laboratory: null,
		labContact: null,
		telephone: null,
		specimenID: null,
		specimenType: null,
		drugAllergies: null,
		reportDate: null,
	},
	pathogens: [], // All available pathogens from the API
	amr: [], // All available AMRs from the API
	status: "idle", // Fetch status for async requests
	error: null, // Any errors from fetching
};

// Thunks for fetching supernames and amr
export const getPathogens = createAsyncThunk(
	"treatment/getPathogens",
	async (_, { getState }) => {
		const token = getState().auth.token;
		const response = await fetchPathogens(token);
		return response;
	}
);

export const getAmr = createAsyncThunk(
	"treatment/getAmr",
	async (_, { getState }) => {
		const token = getState().auth.token;
		const response = await fetchAMR(token);
		return response;
	}
);

export const getTreatment = createAsyncThunk(
	"treatment/getTreatment",
	async ({ token, pathogens, amr }) => {
		const response = await fetchTreatment(token, pathogens, amr);
		return response;
	}
);

export const getTreatment2 = createAsyncThunk(
	"treatment/getTreatment2",
	async ({ token, pathogens, amr, loadLevels }) => {
		const response = await fetchTreatment2(token, pathogens, amr, loadLevels);
		console.log(`===> ${JSON.stringify(response)}`);
		return response;
	}
);

const treatmentSlice = createSlice({
	name: "treatment",
	initialState,
	reducers: {
		setReportData: (state, action) => {
			const {
				pathogens,
				amrs,
				loadLevels,
				careProvider,
				laboratory,
				labContact,
				telephone,
				specimenID,
				specimenType,
				drugAllergies,
				reportDate,
			} = action.payload;

			// Update reportData with provided values
			state.reportData = {
				pathogens: pathogens || [],
				amrs: amrs || [],
				loadLevels: loadLevels || {},
				careProvider: careProvider || "Unknown",
				laboratory: laboratory || "Unknown",
				labContact: labContact || "Unknown",
				telephone: telephone || "Unknown",
				specimenID: specimenID || "Unknown",
				specimenType: specimenType || "Unknown",
				drugAllergies: drugAllergies || "Unknown",
				reportDate: reportDate || "Unknown",
			};
		},
	},
	extraReducers: (builder) => {
		builder
			.addCase(getPathogens.fulfilled, (state, action) => {
				state.pathogens = action.payload;
			})
			.addCase(getAmr.fulfilled, (state, action) => {
				state.amr = action.payload;
			})
			.addCase(getTreatment2.pending, (state) => {
				state.status = "loading";
			})
			.addCase(getTreatment2.fulfilled, (state, action) => {
				state.status = "succeeded";
				state.treatmentData = action.payload; // Populate treatment data
			})
			.addCase(getTreatment2.rejected, (state, action) => {
				state.status = "failed";
				state.error = action.error.message;
			});
	},
});

// Export the reducer and actions
export const { setReportData } = treatmentSlice.actions;
export default treatmentSlice.reducer;
