import api from "./base";

// Example request for fetching lab data
export const fetchPathogens = async (token) => {
	const response = await api.get(`/amr/pathogens`, {
		headers: {
			"Content-Type": "application/json",
			Authorization: `Bearer ${token}`, // Adding Bearer token in the header
		},
	});
	return response.data;
};

export const fetchAMR = async (token) => {
	const response = await api.get(`/amr/amr`, {
		headers: {
			"Content-Type": "application/json",
			Authorization: `Bearer ${token}`, // Adding Bearer token in the header
		},
	});
	return response.data;
};

export const fetchTreatment = async (token, pathogens, amr) => {
	const response = await api.get(
		`/amr/treatment-with-score?pathogen=${pathogens}&amr=${amr}`,
		{
			headers: {
				"Content-Type": "application/json",
				Authorization: `Bearer ${token}`, // Adding Bearer token in the header
			},
		}
	);
	return response.data;
};

export const fetchTreatment2 = async (token, pathogens, amr, loadLevels) => {
	const response = await api.post(
		`/amr/treatment2`,
		{
			pathogens: pathogens,
			resistances: amr,
			loadLevels,
		},
		{
			headers: {
				"Content-Type": "application/json",
				Authorization: `Bearer ${token}`, // Adding Bearer token in the header
			},
		}
	);
	console.log("response: ", response.data);
	return response.data;
};
