// features/treatment/treatmentSlice.js
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { fetchTreatment, fetchPathogens, fetchAMR } from "../../api/treatment";

const initialState = {
  treatmentData: [],
  reportData: {
    pathogens: [],
    amrs:[],
    careProvider: null,
    laboratory: null,
    labContact: null,
    telephone: null,
    specimenID: null,
    specimenType: null,
    drugAllergies: null,
    reportDate: null,
  },
  pathogens: [],
  amr: [],
  status: "idle",
  error: null,
};

// Thunks for fetching supernames and amr
export const getPathogens = createAsyncThunk(
  "treatment/getPathogens",
  async (_, { getState }) => {
    const token = getState().auth.token;
    const response = await fetchPathogens(token);
    return response;
  }
);

export const getAmr = createAsyncThunk(
  "treatment/getAmr",
  async (_, { getState }) => {
    const token = getState().auth.token;
    const response = await fetchAMR(token);
    return response;
  }
);

export const getTreatment = createAsyncThunk(
  "treatment/getTreatment",
  async ({ token, pathogens, amr }) => {
    const response = await fetchTreatment(token, pathogens, amr);
    return response;
  }
);

const treatmentSlice = createSlice({
  name: "treatment",
  initialState,
  reducers: {
    setReportData: (state, action) => {
      const {
        pathogens,
        amrs,
        careProvider,
        laboratory,
        labContact,
        telephone,
        specimenID,
        specimenType,
        drugAllergies,
        reportDate,
      } = action.payload;
      state.reportData = {
        pathogens: pathogens,
        amrs: amrs, 
        careProvider: careProvider || 'Unknown',
        laboratory: laboratory|| 'Unknown',
        labContact: labContact|| 'Unknown',
        telephone: telephone|| 'Unknown',
        specimenID: specimenID|| 'Unknown',
        specimenType: specimenType|| 'Unknown',
        drugAllergies: drugAllergies|| 'Unknown',
        reportDate: reportDate|| 'Unknown',
      };
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getPathogens.fulfilled, (state, action) => {
        state.pathogens = action.payload;
      })
      .addCase(getAmr.fulfilled, (state, action) => {
        state.amr = action.payload;
      })
      .addCase(getTreatment.pending, (state) => {
        state.status = "loading";
      })
      .addCase(getTreatment.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.treatmentData = action.payload;
      })
      .addCase(getTreatment.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      });
  },
});

export const { setReportData } = treatmentSlice.actions;
export default treatmentSlice.reducer;
