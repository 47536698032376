import React, { useEffect } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Login from "./pages/login/Login";
import Main from "./pages/main/Main";
import Report from "./pages/report/Report";
import Report2 from "./pages/report/Report2";
import Report3 from "./pages/report/Report3";
import { useSelector, useDispatch } from "react-redux";
import { restoreSession } from "./data/slices/authSlice";

const App = () => {
	const dispatch = useDispatch();
	const { token } = useSelector((state) => state.auth);

	useEffect(() => {
		// Restore token and user information from sessionStorage on app load
		dispatch(restoreSession());
	}, [dispatch]);

	return (
		<Router>
			<Routes>
				<Route path="/" element={token ? <Main /> : <Login />} />
				<Route path="/treatment" element={token ? <Report /> : <Login />} />
				<Route path="/treatment2" element={token ? <Report2 /> : <Login />} />
				<Route path="/treatment3" element={token ? <Report3 /> : <Login />} />
			</Routes>
		</Router>
	);
};

export default App;
